import React from 'react'
import SectionHeader from '../../common/Header/SectionHeader'
import ContentContainer from '../../common/Layout/ContentContainer'
import Management from './Management'
import OurValues from './OurValues/OurValues'
import FadeInUpAnimation from '../../common/FadeInUpAnimation/FadeInUpAnimation'
import GoodTeam from './GoodTeam/GoodTeam'

const About = () => (
  <section>
    <SectionHeader wordsToType={['About']} />
    <FadeInUpAnimation>
      <ContentContainer
        as='p'
        className='text-4xl leading-snug font-light lg:text-6xl'
      >
        the.good.code; was founded in 2021 by three technology experts with
        international experience working for global leaders like Microsoft,
        Intel, P&G and innovative startups.
      </ContentContainer>
    </FadeInUpAnimation>
    <Management />
    <GoodTeam />
    <OurValues />
  </section>
)

export default About
