import ContentContainer from '../../../common/Layout/ContentContainer'
import TextWithDots from '../../../common/TextWithDots/TextWithDots'
import React from 'react'
import FadeInUpAnimation from '../../../common/FadeInUpAnimation/FadeInUpAnimation'

const GoodTeam = () => (
  <ContentContainer
    as='article'
    className='article-padding xl:grid xl:grid-cols-3 xl:gap-5'
  >
    <FadeInUpAnimation>
      <TextWithDots className='mb-12 xl:col-span-1'>the good team</TextWithDots>
    </FadeInUpAnimation>
    <FadeInUpAnimation className='xl:col-span-2 mb-12 lg:mb-20'>
      <p className='text-2xl leading-8 font-light lg:text-5xl lg:leading-snug'>
        We have a team of more than 15 engineers located in Poland and Portugal.
        In addition we work with a wide network of partner companies providing
        comprehensive services.
      </p>
    </FadeInUpAnimation>
    {/* <Team className='xl:col-span-3' /> */}
  </ContentContainer>
)

export default GoodTeam
