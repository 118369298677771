import { useEffect, useRef } from 'react'
import { gsap } from '../../gsap'

const useLineAnimation = (vars = {}) => {
  const ref = useRef(null)

  useEffect(() => {
    const ctx = gsap.context(() => {
      const linesArr = gsap.utils.toArray('.line')

      linesArr.forEach((line) => gsap.effects.lineAnimation(line, vars))
    }, ref)

    return () => {
      ctx.revert()
    }
  }, [vars])

  return ref
}

export default useLineAnimation
