import React from 'react'
import Layout from '../components/en/Layout/Layout'
import About from '../components/en/About/About'

const AboutPage = () => (
  <Layout
    title='About Us | the.good.code; - Global Team of Technology Specialist'
    description='Learn about the.good.code; a team of technology experts with international experience working for global leaders like Microsoft, Intel, P&G and innovative startups. Discover our mission, vision, and values.'
  >
    <About />
  </Layout>
)

export default AboutPage
