export const ourValues = [
  {
    title: 'People',
    description:
      'Positive relationships are vital for long-term cooperation. We select consultants who are best suited to the customer in terms of skill set, language, and culture with the aim of creating a harmonious working\n' +
      'environment.\n'
  },
  {
    title: 'Business outcomes',
    description:
      'With a focus on achieving your business goal, we consider a wide range of factors and needs best \n' +
      'suited to you. We never cut corners or overengineer.\n'
  },
  {
    title: 'High Quality',
    description:
      'With an average of over ten years’ experience, our expert consultants can provide the highest quality of\n' +
      'service supported by internal processes.\n'
  },
  {
    title: 'Environment',
    description:
      'Our impact on the environment is at the forefront of our decisions when choosing technology. We only work with energy-optimised languages and frameworks\n' +
      'together with shared cloud infrastructures running on sustainable energy.'
  }
]
