import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import ArrowRightIcon from '../Icons/ArrowRightIcon'
import PropTypes from 'prop-types'

const Person = ({ name, position, email, img, role }) => (
  <div className='inline-block'>
    <div className='hidden max-lg:block'>
      <GatsbyImage
        className='rounded-2xl mb-4 lg:mb-5 max-w-xl'
        loading='eager'
        image={img?.childImageSharp?.gatsbyImageData}
        alt={name}
      />
    </div>
    <div className='caption text-left text-brand-primary'>
      <p className='text-black'>{name}</p>
      <p>{position}</p>
      <p>{role}</p>
      {email && (
        <p className='group transition-all duration-300 ease-in-out'>
          <a
            className='bg-left-bottom bg-gradient-to-r from-brand-primary to-brand-primary bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'
            href={`mailto:${email}`}
          >
            {email}
            <ArrowRightIcon className='inline w-4 ml-2 -rotate-45' />
          </a>
        </p>
      )}
    </div>
  </div>
)

export default Person

Person.propTypes = {
  name: PropTypes.string,
  position: PropTypes.string,
  email: PropTypes.string,
  img: PropTypes.object,
  role: PropTypes.string
}

Person.defaultProps = {
  name: '',
  position: '',
  email: '',
  role: '',
  img: {}
}
