import React from 'react'
import ArrowRightIcon from '../../Icons/ArrowRightIcon'
import PropTypes from 'prop-types'
import FadeInUpAnimation from '../../FadeInUpAnimation/FadeInUpAnimation'

const Value = ({ title, description }) => (
  <div className='mb-10 last:mb-0 lg:mb-20 xl:grid xl:grid-cols-12 overflow-hidden'>
    <div className='line h-[1px] w-full bg-line-grey xl:col-span-12' />
    <FadeInUpAnimation className='h-fit flex items-center my-5 xl:col-span-5'>
      <ArrowRightIcon className='text-brand-primary w-6 mr-4' />
      <h3 className='text-2xl leading-8 font-light lg:text-5xl lg:leading-snug'>
        {title}
      </h3>
    </FadeInUpAnimation>
    <FadeInUpAnimation
      className='hyphens-auto xl:mt-8 xl:col-span-7 text-lg sm:text-2xl leading-6 sm:leading-8'
      as='p'
    >
      {description}
    </FadeInUpAnimation>
  </div>
)

export default Value

Value.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
}

Value.defaultProps = {
  title: '',
  description: ''
}
